import React from 'react';
import { Color, colorLabels } from '../types/Color';

// Original Source: https://www.svgrepo.com/svg/17512/cotton-cardigan
// Original Source SVG License: CC0 License
// New SVG created using Inkscape by selecting `Paths > Break Apart` and then deleting the background.
// My guide for this: https://blog.blakez.dev/create-svg-components-with-react-and-typescript/
const CardiganIcon = ({
  height = '100px',
  width = '100px',
  color = '#000000',
}: React.SVGProps<SVGSVGElement> & { color?: Color }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 381.375 381.375"
    >
      <title>{colorLabels[color]}</title>
      <path
        fill={color}
        d="m 336.8,350.219 h -39.006 c -2.088,-2.309 -7.457,-9.94 -10.596,-29.678 22.986,-106.333 11.084,-192.402 10.959,-193.262 -0.078,-0.543 -0.244,-1.068 -0.492,-1.557 -13.416,-26.381 -17.191,-50.996 -11.219,-73.162 2.756,-10.234 7.074,-17.557 9.973,-21.66 9.514,10.34 35.822,48.029 39.994,151.611 1.352,33.574 2.891,60.035 4.127,81.297 3.051,52.487 4.184,71.955 -3.74,86.411 z"
        id="path213"
      />
      <path
        fill={color}
        d="m 331.669,371.375 h -27.824 l -2,-11.156 h 31.824 z"
        id="path211"
      />
      <path
        fill={color}
        d="m 195.688,315.406 v -20.459 h 86.319 c -1.172,6.717 -2.477,13.54 -3.934,20.459 z"
        id="path209"
      />
      <path
        fill={color}
        d="m 103.303,315.406 c -1.458,-6.919 -2.762,-13.742 -3.934,-20.459 h 86.318 v 20.459 z"
        id="path207"
      />
      <path
        fill={color}
        d="M 83.58,350.219 H 44.576 c -7.924,-14.455 -6.791,-33.924 -3.74,-86.402 1.236,-21.264 2.775,-47.727 4.127,-81.305 C 47.598,117.045 59.317,78.364 68.684,57.442 75.096,43.12 81.289,34.985 84.979,30.936 c 2.9,4.113 7.2,11.42 9.949,21.625 5.973,22.166 2.199,46.781 -11.219,73.162 -0.248,0.488 -0.414,1.014 -0.492,1.557 -0.123,0.859 -12.026,86.929 10.96,193.262 -3.138,19.736 -8.509,27.368 -10.597,29.677 z"
        id="path205"
      />
      <path
        fill={color}
        d="M 77.53,371.375 H 49.705 L 47.706,360.219 H 79.53 Z"
        id="path203"
      />
      <path
        fill={color}
        d="m 133.912,11.916 c 3.143,29.482 25.904,55.156 51.775,58.137 V 284.948 H 97.707 C 84.576,201.604 92.016,137.29 93.012,129.489 110.912,93.848 109.11,66.251 104.412,49.331 101.461,38.7 97.058,30.854 93.722,25.974 Z"
        id="path201"
      />
      <path
        fill={color}
        d="m 190.688,28.556 c 23.154,0 36.927,-4.003 45.12,-8.676 -2.334,8.627 -6.617,16.85 -12.514,23.729 -9.12,10.633 -21.005,16.732 -32.606,16.732 -11.604,0 -23.488,-6.1 -32.605,-16.732 -5.898,-6.879 -10.181,-15.102 -12.514,-23.728 8.193,4.672 21.964,8.675 45.119,8.675 z"
        id="path199"
      />
      <path
        fill={color}
        d="m 247.464,11.916 40.189,14.057 c -3.338,4.881 -7.74,12.726 -10.691,23.357 -4.697,16.92 -6.498,44.518 11.402,80.158 0.994,7.801 8.434,72.115 -4.695,155.459 H 195.688 V 70.052 c 25.87,-2.98 48.634,-28.654 51.776,-58.136 z"
        id="path197"
      />
    </svg>
  );
};

export default CardiganIcon;
